<template>
    <div>
        <div v-if="!success">
            <h4> {{  $t('Security alert') }}</h4>
            <p> {{  $t('A new login was detected on your account.') }}</p>
            <div>
                <list-group-skeleton v-if="loading" :rows="3" />
                <list v-else bordered>
                    <list-item class="px-3" title="Account:">
                        <template #text>
                            {{ $route.query.email }}
                        </template>
                    </list-item>
                    <list-item title="Fingerprint:">
                        <template #text>
                            {{ device.fingerprint }}
                        </template>
                    </list-item>
                    <list-item class="px-3" title="Location:">
                        <template #text>
                            {{ device.location }}
                        </template>
                    </list-item>
                    <list-item title="Device type:">
                        <template #text>
                            {{ device.platform }}
                        </template>
                    </list-item>
                    <list-item title="Time:">
                        <template #text>
                            {{ format_date }}
                        </template>
                    </list-item>
                </list>
            </div>
            <div>
                <br>
                <p> {{ $t('Do you recognize this activity?') }}</p>
                <ui-button :loading="closing" class="w-100 mb-0" type="submit" variant="accent" @click="closeSession">
                    {{ $t('No, close session.') }}
                </ui-button>
            </div>
        </div>
        <div v-else-if="success">
            <empty-state img-size="md" img="safe.svg" text="This action helps us protect your account." />
        </div>
    </div>
</template>

<script>
import List from '@/components/ui/list/List';
import ListItem from '@/components/ui/list/ListItem';
import ListGroupSkeleton from '@/components/ui/skeletons/ListGroupSkeleton.vue';
import UiButton from '@/components/ui/buttons/Button.vue';
import EmptyState from '@/components/ui/EmptyState.vue';

export default {
    name: 'ReportDeviceCard',
    components: { List, ListItem, ListGroupSkeleton, UiButton, EmptyState },
    data() {
        return {
            device: {},
            loading: false,
            format_date: '',
            closing: false,
            opening: false,
            counter: 0,
            success: false,
        }
    },
    async mounted() {
        await this.getDevice();
        await this.checkDeviceStatus();
    },
    methods: {
        async getDevice() {
            try {
                this.loading = true;
                const { data } = await this.axiosAccount.get(`/api/accounts/devices`, { params: { email: this.$route.query.email, device: this.$route.query.device, created_at: this.$route.query.created_at } });
                this.device = data.device;
                this.counter = data.number_reports;
                this.loading = false;
                this.formatDate(this.device.created_at);
            } catch (err) {
                this.$toasted.global.errorMessage(err.response.data.message || 'Error trying to get device information.');
            } finally {
                this.loading = false;
            }
        },
        formatDate(date) {
            let format = new Date(date).toLocaleString();
            this.format_date = format.slice(0, -3);
        },
        checkDeviceStatus() {
            if(this.device.unrecognized === true) {
                this.success = true;
            }
        },
        async closeSession() {
            try {
                this.closing = true;
                let body = { email: this.$route.query.email, device: this.$route.query.device, created_at: this.$route.query.created_at };
                await this.axiosAccount.put(`/api/accounts/devices/report`, body);
                this.$toasted.global.successMessage('Session closed successfully.');
            } catch (err) {
                console.log(err);
                this.$toasted.global.errorMessage('Error closing session. Please try again.');
            } finally {
                this.closing = false;
            }
        },
    }
}
</script>

<style lang="scss">
.report-device-card {

    &__empty-message {
        background-color: #eff1f8;
        border-radius: 8px;
        padding: 16px;
        text-align: center;
        width: 100%;
        margin-bottom: 0;
    }

    &__separator {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
        position: relative;
    }

    &__separator-text {
        font-size: 14px;
        color: #9093A1;
        font-weight: 500;
        padding-left: 10px;
        padding-right: 10px;

        &::after {
            background-color: #c4c4c4;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            top: calc(50% - 1px);
            width: calc(50% - 18px);
        }

        &::before {
            background-color: #c4c4c4;
            content: '';
            height: 1px;
            position: absolute;
            right: 0;
            top: calc(50% - 1px);
            width: calc(50% - 18px);
        }
    }

    &__forgot-password {
        color: $accounts-secondary !important;
        font-weight: 500 !important;
        padding: 0 !important;
        text-align: left;
        font-size: 8px;
        padding-top: 0%;
    }
}
</style>