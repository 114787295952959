<template>
    <div class="report-device-page">
        <div class="report-device-page__content">
            <br>
            <logos-container-ui center class="mb-3" />
            <info-device/>
        </div>
    </div>
</template>

<script>
import LogosContainerUi from '@/components/ui/LogosContainer.vue';
import InfoDevice from '@/components/devices/InfoDevice.vue';

export default {
    name: 'report-device-page',
    components: { 
        LogosContainerUi,
        InfoDevice,
    }
};
</script>

<style lang="scss">
.report-device-page {
    text-align: center;
    position: relative;

    &__content {
        max-width: 650px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>